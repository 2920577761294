import { getApplicationSecondaryType } from 'app-sections/common/applications/details/components/sections/status-info/components/general-info/helpers';
import { getPropertyUseCategoriesInfo } from 'app-sections/common/applications/summary-sections/property-size/components/use-summary/helpers';
import { getCertificateNameById } from 'app-sections/user/applications/steps/step5/config/config';
import { get } from 'lodash-es';
import { Application } from 'types';
import AddressFormatted from 'ui/components/address-formatted/AddressFormatted';
import LinesSkeleton from 'ui/components/skeletons/lines-skeleton/LinesSkeleton';
import { formatPhoneNumber } from 'ui/helpers/maskedValues';

import { Box, Grid, Typography } from '@mui/material';

import { getApplicationTypeLabelById } from '../../../app-sections/user/applications/steps/step3/config';
import SummaryTextGrid from '../summary-text-grid/SummaryTextGrid';

export interface SummaryHeaderProps {
  isLoading?: boolean;
  application: Application;
  isExtended: boolean;
}

export default function SummaryHeader(props: SummaryHeaderProps) {
  const { isLoading = false, application, isExtended } = props;

  const ownersData = application.propertyOwners;
  const propertyAddress = application.propertyAddress;
  const typeOfApplication = application.typeOfApplication;

  const changeTypes = get(application, 'propertyChangeTypes') || [];
  const useWillBeChanged = changeTypes.includes('proposedUse');

  const propertyUse = useWillBeChanged ? application.proposedUse : application.presentUse;

  const applicantContact = isExtended ? get(application, 'applicantPersonalInformation.phone') : '';

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <Typography component="h3" variant="h3">
          Property Address
        </Typography>
        <Box fontSize="2rem">
          {!isLoading && (
            <span>
              <AddressFormatted address={propertyAddress} commaSeparated={false} />
            </span>
          )}
          {isLoading && (
            <Box mt={1}>
              <LinesSkeleton elements={3} spacing={3} isWithButtons={false} />
            </Box>
          )}
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={typeOfApplication ? 4 : 6} lg={typeOfApplication ? 4 : 6}>
        <Typography component="h3" variant="h3">
          Property Owners
        </Typography>
        <Box fontSize="2rem">
          {!isLoading && ownersData?.length > 0 && (
            <Grid container spacing={2}>
              {ownersData.map((owner, index) => (
                <Grid key={index} item xs={12} sm={12} md={6} lg={6}>
                  <span>{owner.ownerFullName}</span>
                  <br />
                  <span>{owner.mailAddress}</span>
                </Grid>
              ))}
            </Grid>
          )}
          {isLoading && (
            <Box mt={1}>
              <LinesSkeleton elements={3} spacing={3} isWithButtons={false} />
            </Box>
          )}
        </Box>
      </Grid>
      {typeOfApplication && (
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Typography component="h3" variant="h3">
            Application Summary
          </Typography>
          <Box fontSize="2rem">{getApplicationTypeLabelById(typeOfApplication)}</Box>
          {typeOfApplication === 'change' && <Box mb={2}>{getApplicationSecondaryType(application)}</Box>}
          {application.typeOfCertificate && (
            <Box mt={2} fontSize="2rem">
              <Typography component="h3" variant="h3">
                Type of Certificate
              </Typography>
              <Box>{getCertificateNameById(application.typeOfCertificate) || ''}</Box>
            </Box>
          )}
        </Grid>
      )}
      {isExtended && applicantContact && (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography component="h3" variant="h3">
            Application Contact Info
          </Typography>
          <Box fontSize="2rem">{formatPhoneNumber(applicantContact)}</Box>
        </Grid>
      )}
      {isExtended && propertyUse && (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography component="h3" variant="h3">
            Proposed Use
          </Typography>
          <Box mt={2} sx={{ overflow: 'hidden' }}>
            <SummaryTextGrid items={getPropertyUseCategoriesInfo(propertyUse.data, true)} firstColumnWidth={4} />
          </Box>
        </Grid>
      )}
    </Grid>
  );
}
