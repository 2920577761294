import AdminProfilesIcon from 'ui/icons/AdminProfilesIcon.';
import BookingsIcon from 'ui/icons/BookingsIcon';
import DashboardIcon from 'ui/icons/DashboardIcon';
import FileTypePdfIcon from 'ui/icons/FileTypePdfIcon';
import LogoutIcon from 'ui/icons/LogoutIcon';
import ProfileIcon from 'ui/icons/ProfileIcon';
import SettingsIcon from 'ui/icons/SettingsIcon';
import UsersProfilesIcon from 'ui/icons/UsersProfilesIcon.';

import { INavigationElement } from '../NavigationElement';

const ADMNIN_REVIEWER_GUIDE_URL = 'https://docskit-dob-admin-reviewer.staging.oregon.platform-os.com/cofo/';

export const getAdminNavigation = (id: string): INavigationElement[] => [
  {
    id: 'dashboard',
    name: 'Dashboard',
    url: '/admin',
    path: '/admin',
    icon: <DashboardIcon />,
    disabled: false,
    show: true,
  },
  {
    id: 'client-projects',
    name: 'Applications',
    url: '/admin/applications',
    path: '/admin/applications/*',
    icon: <BookingsIcon />,
    disabled: false,
    show: true,
  },
  {
    id: 'user-management',
    name: 'Certifi Users',
    url: '/admin/user-profiles',
    path: '/admin/user-profiles',
    icon: <UsersProfilesIcon />,
    disabled: false,
    show: true,
  },
  {
    id: 'admin-management',
    name: 'Certifi Admins',
    url: '/admin/admin-profiles',
    path: '/admin/admin-profiles',
    icon: <AdminProfilesIcon />,
    disabled: false,
    show: true,
  },
  {
    id: 'admin-profile',
    name: 'Profile',
    url: `/admin/admin-profiles/${id}/edit`,
    path: `/admin/admin-profiles/${id}/edit`,
    icon: <ProfileIcon />,
    disabled: false,
    show: true,
  },
  {
    id: 'guide',
    name: `User Guide`,
    url: ADMNIN_REVIEWER_GUIDE_URL,
    path: '/guide',
    icon: <FileTypePdfIcon />,
    disabled: false,
    show: true,
    isExternal: true,
  },
  {
    id: 'settings',
    name: 'Settings',
    url: '/admin/settings',
    path: ['/admin/settings', '/admin/settings/general'],
    icon: <SettingsIcon />,
    disabled: false,
    show: true,
    settings: true,
  },
  {
    id: 'logout',
    name: 'Logout',
    url: '/auth/logout',
    path: '/logout',
    icon: <LogoutIcon />,
    disabled: false,
    show: true,
    settings: true,
  },
];
