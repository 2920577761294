import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { IFireworksSettings } from 'types';

dayjs.extend(isBetween);

/**
 * Check if fireworks are allowed based on the settings
 * @returns boolean
 */
export const isFireworksAllowed = (settings: IFireworksSettings): boolean => {
  if (!settings.isActive) {
    return countDefaultDates();
  }

  const dateStart = dayjs(settings.dateFrom);
  const dateEnd = dayjs(settings.dateTo);
  return dayjs().isBetween(dateStart, dateEnd, 'day', '[]');
};

/**
 * Fireworks type by default is available for the applicants to apply from last Monday of May month and until July 1st and expiration of the CO will be July 5th
 * @returns
 */
export const countDefaultDates = (): boolean => {
  // Initialize to the last day of May for the current year
  let dateStart = dayjs().month(4).date(31); // May is month 4 (0-indexed)

  // Find the last Monday in May
  // If the day is already Monday, it will stay the same; otherwise, it will go back to the last Monday
  dateStart = dateStart.day(dateStart.day() >= 1 ? 1 : -6);

  const dateEnd = dayjs().month(6).date(1); // July 1st
  return dayjs().isBetween(dateStart, dateEnd, 'day', '[)');
};
