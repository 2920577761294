import { SvgIcon, SvgIconProps } from '@mui/material';

export default function SettingsIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props} viewBox="0 0 26 25" fill="none">
      <g fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.3369 2.43763C11.7358 2.84958 12.3039 3.08477 12.9 3.08477C13.496 3.08477 14.0641 2.84958 14.463 2.43763L15.4799 1.39963C16.0772 0.784771 17.0257 0.585987 17.8487 0.903209C18.6716 1.22043 19.191 1.98503 19.1457 2.81263L19.0694 4.21263C19.0395 4.7649 19.2621 5.30317 19.6821 5.69446C20.1022 6.08575 20.6805 6.29359 21.2742 6.26663L22.7792 6.19563C23.6683 6.15485 24.4889 6.63848 24.829 7.40369C25.1692 8.16891 24.9553 9.05041 24.295 9.60563L23.1748 10.5456C22.7325 10.9172 22.4801 11.4457 22.4801 12.0001C22.4801 12.5545 22.7325 13.083 23.1748 13.4546L24.295 14.3946C24.9559 14.9502 25.1696 15.8325 24.8286 16.5981C24.4876 17.3636 23.6656 17.8468 22.776 17.8046L21.271 17.7336C20.6759 17.7052 20.0958 17.9126 19.6745 18.3045C19.2532 18.6964 19.0302 19.2361 19.0608 19.7896L19.1371 21.1896C19.1765 22.0129 18.6579 22.771 17.8396 23.0865C17.0212 23.4021 16.0781 23.2074 15.4799 22.5996L14.4684 21.5586C14.0691 21.1473 13.5011 20.9125 12.9053 20.9125C12.3095 20.9125 11.7416 21.1473 11.3423 21.5586L10.3264 22.5996C9.72913 23.2103 8.78436 23.4071 7.96414 23.0917C7.14391 22.7763 6.62431 22.0164 6.66495 21.1916L6.74235 19.7916C6.77296 19.2381 6.54995 18.6984 6.12863 18.3065C5.70732 17.9146 5.12719 17.7072 4.53215 17.7356L3.02715 17.8066C2.13784 17.8498 1.31555 17.3677 0.973775 16.6027C0.632003 15.8378 0.844716 14.9555 1.50495 14.3996L2.62403 13.4596C3.0663 13.088 3.31871 12.5595 3.31871 12.0051C3.31871 11.4507 3.0663 10.9222 2.62403 10.5506L1.50495 9.60563C0.846931 9.05038 0.634533 8.17059 0.97414 7.40692C1.31375 6.64326 2.13223 6.16017 3.01963 6.19963L4.52463 6.27063C5.12081 6.29979 5.70227 6.09206 6.12421 5.69917C6.54616 5.30628 6.7689 4.76519 6.73698 4.21063L6.66495 2.80963C6.62334 1.9845 7.14259 1.22375 7.96306 0.907791C8.78352 0.591835 9.72888 0.788574 10.3264 1.39963L11.3369 2.43763Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.9 16.5C15.5717 16.5 17.7375 14.4853 17.7375 12C17.7375 9.51472 15.5717 7.5 12.9 7.5C10.2283 7.5 8.0625 9.51472 8.0625 12C8.0625 14.4853 10.2283 16.5 12.9 16.5Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
