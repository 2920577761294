import { markAsPaid } from 'core/api/applications';
import { useAuthStore } from 'core/store/auth';
import { useEffect, useState } from 'react';
import ButtonLoader from 'ui/components/button-loader/ButtonLoader';

import { Box, Button, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Application } from 'types';
import { ConfirmationDialog } from 'ui/components/confirmation-dialog/ConfirmationDialog';
import { isAdmin } from 'ui/helpers/users';

export interface MarkAsPaidButtonProps {
  application: Application;
}

export default function MarkAsPaidButton(props: MarkAsPaidButtonProps) {
  const { application } = props;
  const { currentUser } = useAuthStore();

  const { isFetching, data, refetch, isSuccess } = useQuery({
    queryKey: ['application/mark-as-paid', application.id],
    queryFn: () => markAsPaid(application.id),
    enabled: false,
  });

  const markAsPaidAction = () => {
    refetch();
  };

  const [dialogState, setDialogState] = useState<boolean>(false);

  useEffect(() => {
    if (isSuccess) {
      window.location.reload();
    }
  }, [isSuccess]);

  if (
    !application ||
    application.state !== 'pending_payment' ||
    !currentUser ||
    !isAdmin(currentUser.userProfile.roles) ||
    !(currentUser.email.includes('@near-me.com') || currentUser.email === 'jyothi.reddy@dc.gov')
  ) {
    return null;
  }

  return (
    <>
      <ConfirmationDialog
        title="Mark as Paid"
        description={
          <Box mb={2} padding={0} maxWidth="576px">
            <Box mt={2} textAlign="center" color="main.dark">
              <Typography component="h2" variant="h2">
                Confirm mark as paid
              </Typography>
            </Box>
            <Box fontSize="2rem" textAlign="center">
              You are about to mark application #{application.certificatePermitNumber}, created by{' '}
              {application.clientProfile?.fullName}({application.clientProfile?.email}) as paid. Are you sure?
            </Box>
          </Box>
        }
        opened={dialogState}
        confirmButtonVariant="contained"
        onConfirm={() => {
          markAsPaidAction();
        }}
        onCancel={() => {
          setDialogState(false);
        }}
      />
      <Box sx={{ position: 'relative', marginLeft: '8px' }} mb={1}>
        <Button
          variant="outlined"
          size="small"
          sx={{ fontSize: '1.1rem', minWidth: '65px' }}
          onClick={() => setDialogState(true)}
          disabled={isFetching || data?.isLoading}
        >
          <ButtonLoader isActive={isFetching || data?.isLoading} size={16} />
          Mark as Paid
        </Button>
      </Box>
    </>
  );
}
