import { get } from 'lodash-es';
import { AccelaPermit, Application, IFireworksSettings, Params, Settings } from 'types';

import { isFireworksAllowed } from '../components/certificate-type-picker/picker-option/helpers';

export type CertificateTypesVariantsId =
  | 'conditional'
  | 'permanent'
  | 'farmersMarket'
  | 'christmasTrees'
  | 'fireworks'
  | 'coreAndShell'
  | 'skatingRink'
  | 'outdoorScreens'
  | 'festivals'
  | 'other';

export function getInitialVariants(): {
  [key in CertificateTypesVariantsId]: Params;
} {
  return {
    conditional: { isValid: false, data: {} },
    permanent: { isValid: true, data: {} },
    coreAndShell: { isValid: true, data: {} },
    farmersMarket: { isValid: false, data: {} },
    christmasTrees: { isValid: false, data: {} },
    fireworks: { isValid: false, data: {} },
    skatingRink: { isValid: false, data: {} },
    outdoorScreens: { isValid: false, data: {} },
    festivals: { isValid: false, data: {} },
    other: { isValid: false, data: {} },
  };
}

export function setVariantInitialData(
  data: Application,
  config: Step5Config
): {
  [key in CertificateTypesVariantsId]: Params;
} {
  let variants = getInitialVariants();
  if (data.typeOfCertificate) {
    variants[data.typeOfCertificate] = data.conditionalDetails;

    // check if data are still valid (if user changed something in step 3 and conditions doesn't match anymore)
    if (config.isConditionalTypeExtended && !variants[data.typeOfCertificate]?.data?.shellStatus) {
      variants[data.typeOfCertificate].isValid = false;
    }

    if (!config.isConditionalTypeExtended && variants[data.typeOfCertificate]?.data?.shellStatus) {
      variants[data.typeOfCertificate].data.shellStatus = '';
    }
  }

  return variants;
}

export function setPermitNumbersInitialData(data: Application, config: Step5Config): AccelaPermit[] {
  if (config.isPermitsPickerRequired) {
    return data?.permitNumbers || [];
  }
  return [];
}

export function getVariant(
  typeOfCertificate: CertificateTypesVariantsId | '',
  config: Step5Config
): CertificateTypesVariantsId | '' {
  if (typeOfCertificate !== '' && config.activeTypes.includes(typeOfCertificate)) {
    // validate fireworks type
    if (typeOfCertificate === 'fireworks' && !isFireworksAllowed(config.fireworksSettings)) {
      return '';
    }
    return typeOfCertificate;
  }
  return '';
}

export const prepareDataToSave = (
  data: Params,
  variant: CertificateTypesVariantsId,
  siteContactInformation: Params,
  permitNumbers: AccelaPermit[]
): Params => {
  return {
    typeOfCertificate: variant,
    conditionalDetails: data,
    siteContactInformation,
    permitNumbers,
    typeOfCertificateOther: data?.data?.useDescription ?? null,
  };
};

export const TIMEFRAME_SELECT_OPTIONS = [
  {
    id: '30days',
    name: '30 days',
  },
  {
    id: '60days',
    name: '60 days',
  },
  {
    id: '90days',
    name: '90 days',
  },
  {
    id: 'custom',
    name: "Custom (at DOB's discretion)",
  },
];

export const getTimeFrameById = (id: string): string => {
  return TIMEFRAME_SELECT_OPTIONS.find((item) => item.id === id)?.name || '';
};

export interface Step5Config {
  activeTypes: CertificateTypesVariantsId[];
  isConditionalTypeExtended: boolean;
  isPermitsPickerRequired: boolean;
  fireworksSettings: IFireworksSettings;
}

export const createConfig = (data: Application, settings: Settings): Step5Config => {
  const config: Step5Config = {
    activeTypes: [],
    isConditionalTypeExtended: false,
    isPermitsPickerRequired: true,
    fireworksSettings: settings.fireworksSettings,
  };

  if (data.typeOfApplication === 'change') {
    config.activeTypes = ['permanent', 'conditional'];
  }

  if (data.typeOfApplication === 'newConstruction') {
    config.activeTypes = ['permanent', 'conditional', 'coreAndShell'];
    config.isConditionalTypeExtended = true;
  }

  if (data.typeOfApplication === 'temporarySeasonal') {
    config.activeTypes = [
      'christmasTrees',
      'fireworks',
      'farmersMarket',
      'other',
      'festivals',
      'outdoorScreens',
      'skatingRink',
    ];
  }

  const anyRenovations = get(data, 'ownerChange.data.questions[0].state');
  if (
    data.typeOfApplication === 'twoFamilyFlat' ||
    (data.typeOfApplication === 'change' &&
      data.propertyChangeTypes.length === 1 &&
      data.propertyChangeTypes[0] === 'ownerChange' &&
      !anyRenovations)
  ) {
    config.activeTypes = ['permanent'];
    config.isConditionalTypeExtended = false;
    config.isPermitsPickerRequired = false;
  }

  return config;
};

export const CERTIFICATE_TYPES: {
  id: CertificateTypesVariantsId;
  name: string;
}[] = [
  { id: 'conditional', name: 'Conditional' },
  { id: 'permanent', name: 'Permanent' },
  { id: 'farmersMarket', name: 'Farmers Market' },
  { id: 'christmasTrees', name: 'Christmas Trees' },
  { id: 'fireworks', name: 'Fireworks' },
  { id: 'coreAndShell', name: 'Core And Shell' },
  { id: 'skatingRink', name: 'Skating rink' },
  { id: 'outdoorScreens', name: 'Outdoor screens' },
  { id: 'festivals', name: 'Festivals' },
  { id: 'other', name: 'Other' },
];

export const getCertificateNameById = (id: CertificateTypesVariantsId): string => {
  return CERTIFICATE_TYPES.find((item) => item.id === id)?.name || '';
};
